
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'form dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'image',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container form-input quickSearchWrap' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'cm_searchInput' }, 'What are you looking for?');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '6132'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '919'
        }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
        _createElement('div', {
            'className': 'cm_search-box_submit',
            'title': 'Search',
            'aria-label': 'search button',
            'data-cm-role': 'add-query',
            'key': '6134'
        }, [_createElement('i', {
                'className': 'icon',
                'aria-hidden': 'true',
                'key': '12080'
            }, _createElement('svg', {
                'viewBox': '0 0 19 19',
                'stroke': 'none',
                'strokeWidth': '1',
                'fill': 'none',
                'fillRule': 'evenodd'
            }, _createElement('path', { 'd': 'M19,17.6488357 L17.6488357,19 L12.6148521,13.9660164 C11.2038948,15.0660848 9.60164522,15.6161108 7.80805538,15.6161108 C6.77175903,15.6161108 5.77732803,15.4188188 4.82473254,15.0242291 C3.87213705,14.6296393 3.02517677,14.0656634 2.28382631,13.3322845 C1.55044735,12.5989055 0.986471451,11.7559309 0.591881687,10.8033354 C0.197291923,9.85073994 0,8.85232324 0,7.80805538 C0,6.76378752 0.197291923,5.76537082 0.591881687,4.81277533 C0.986471451,3.86017984 1.55044735,3.01720526 2.28382631,2.28382631 C3.02517677,1.55044735 3.87213705,0.986471451 4.82473254,0.591881687 C5.77732803,0.197291923 6.77175903,0 7.80805538,0 C8.85232324,0 9.85073994,0.197291923 10.8033354,0.591881687 C11.7559309,0.986471451 12.5989055,1.55044735 13.3322845,2.28382631 C14.0736349,3.01720526 14.6415965,3.86017984 15.0361863,4.81277533 C15.430776,5.76537082 15.628068,6.76378752 15.628068,7.80805538 C15.628068,9.59367371 15.0740563,11.1959233 13.9660164,12.6148521 L19,17.6488357 Z M3.62303335,11.9811202 C4.77890236,13.1369892 6.17389575,13.714915 7.80805538,13.714915 C9.44221501,13.714915 10.8372084,13.1369892 11.9930774,11.9811202 C13.1409749,10.8332227 13.714915,9.44221501 13.714915,7.80805538 C13.714915,6.18186726 13.1409749,4.78687387 11.9930774,3.62303335 C10.8292369,2.47513586 9.4342435,1.90119572 7.80805538,1.90119572 C6.17389575,1.90119572 4.78288806,2.47513586 3.63499056,3.62303335 C2.47912155,4.77890236 1.90119572,6.17389575 1.90119572,7.80805538 C1.90119572,9.44221501 2.47513586,10.8332227 3.62303335,11.9811202 Z' })))])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function onError1_facetValue(e) {
                                        e.target.classList.add('cm_hide');
                                    }
                                    function mergeProps_facetValue(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                        'src': this.imageOrDefault(this.images_image_thumbnail1 || this.image_thumbnail || this.images_image_standard1 || this.image_standard),
                                        'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.mpn) || this.removeHTML(this.name)),
                                        'onError': this.onImageError
                                    })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.name } })), _createElement('div', { 'className': 'price' }, this.formatPrice(this.price)))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                        'className': 'image-block',
                                        'key': '35'
                                    }, _createElement('img', {
                                        'src': this.imageUrl,
                                        'alt': 'Image of ' + this.facetValue,
                                        'onError': onError1_facetValue.bind(this)
                                    })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                        'className': 'hit-count',
                                        'key': '335'
                                    }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'section' }, this.sectionTitle ? _createElement('div', {
                            'className': 'section-title-div',
                            'key': '29'
                        }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), this.browseAllButton ? [[this.browseAllButton(function () {
                        return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]
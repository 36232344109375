//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-468:_72,_876,_695,_612,_215,_836,_420,_252;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-468')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-468', "_72,_876,_695,_612,_215,_836,_420,_252");
        }
      }catch (ex) {
        console.error(ex);
      }